@font-face {
  font-family: "Museo Sans";
  src: url("./static/fonts/Museo/MuseoSansCyrl-300.ttf");

  font-weight: 300;
}
@font-face {
  font-family: "Museo Sans";
  src: url("./static/fonts/Museo/MuseoSansCyrl-500.ttf");

  font-weight: 500;
}
@font-face {
  font-family: "Museo Sans";
  src: url("./static/fonts/Museo/MuseoSansCyrl-300Italic.ttf");

  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Museo Sans";
  src: url("./static/fonts/Museo/MuseoSansCyrl-500Italic.ttf");

  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Museo Sans";
  src: url("./static/fonts/Museo/MuseoSansCyrl-900.ttf");

  font-weight: 900;
}
